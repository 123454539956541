@import '../../pages/capeb/variables';

.capeb-navbar {
  background: $color__primary;
  color: $color__light;
  padding: 1rem 0px;
  
  &__item {
    position: relative;
    
    > .capeb-navbar {
      &__text,
      &__link {
        display: block;
        text-decoration: none;
        color: inherit;
        padding: 0.5rem 1rem;
        border: 1px solid $color__light;
        border-radius: 0.5rem;
        cursor: pointer;
        transition: all 0.3s linear;
      }
    }
    
    &:hover > .capeb-navbar {
      &__text,
      &__link {
        box-shadow: 0px 0px 5px rgba(black, 0.25);
        background: rgba(white, 0.1);
      }
      
      &__submenu {
        display: block;
        opacity: 1;
      }
    }
    
    &--active > .capeb-navbar {
      &__text,
      &__link {
        border-color: $color__yellow;
      }
    }
  }
  
  &__submenu {
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1;
    left: 0px;
    right: 0px;
    background: $color__primary;
    margin: 0;
    list-style-type: none;
    padding: 0.25rem 0px;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0px 5px 5px rgba(black, 0.25);
    transition: all 1s linear;
    opacity: 0;
  }
  
  &__subitem {
    border-bottom: 1px solid rgba(white, 0.25);
    
    &:last-child {
      border-bottom: none;
    }
    
    &--active {
      color: $color__yellow;
    }
    
    a {
      padding: 0.375rem 1rem;
      display: block;
      text-decoration: none;
      color: inherit;
      transition: all 0.3s linear;
      
      &:hover {
        background: rgba(white, 0.1);
      }
    }
  }
  
  
  
  @include responsive (lg) {
    padding: 0.75rem 0px;
    
    &__item {
      > .capeb-navbar {
        &__text,
        &__link {
          padding: 0.25rem 0.75rem;
          font-size: 0.875rem;
        }
      }
    }
    
    &__submenu {
      padding: 0.25rem 0px;
      border-radius: 0.25rem;
    }
    
    &__subitem {
      
      a {
        font-size: 0.875rem;
        padding: 0.375rem 1rem;
      }
    }
  }
}