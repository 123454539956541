@import '../../pages/capeb/variables';

.capeb-source {
  display: flex;
    
  &__inner {
    margin-left: auto;
  }
  
  &__block {
    font-size: 0.75rem;
    background: rgba($color__grey, 0.25);
    padding: 0.5rem 1rem;
    margin: 1rem 0px 1rem;
    border-radius: 0.5rem;
  }
  
  &__title {
    font-size: 150%;
    margin: 0px 0px 0.25rem;
    border-bottom: 1px solid $color__dark;
  }
  
  &__traitement {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
  }
  
  &__trtimg {
    width: 50px;
    max-width: 100%;
    height: auto;
    margin-right: 0.25rem;
  }
  
  &__trttext {
    font-size: 65%;
    margin: 0.25rem 0px;
    opacity: 0.85;
  }
  
  @include responsive(lg) {
    &__block {
      font-size: 0.5rem;
    }
  }
}