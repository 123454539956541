@import '../../pages/capeb/variables';

.capeb-filters {
  padding: 0.5rem 0px;
  background: $color__grey;
  // color: $color__light;
  margin-bottom: 0.5rem;
  
  .form-label {
    text-transform: uppercase;
    font-weight: bold;
    font-family: $font__title;
    text-transform: uppercase;
    color: $color__primary;
  }
  
  
  @include responsive (lg) {
    padding: 0.25rem 0px;
    font-size: 0.875rem;
    .form-label {
      margin-bottom: 0.25rem;
    }
  }
  
  @include responsive (md) {
    font-size: 0.75rem;
  }
}


.capeb-filter {
  &__group {
    margin-bottom: 0.75rem;
  }
  
  @include responsive (lg) {
    &__group {
      margin-bottom: 0.5rem;
    }
  }
  
  @include responsive (md) {
    &__group {
      margin-bottom: 0.25rem;
    }
  }
}