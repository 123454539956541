
// -------------------------------
// COLORS
// -------------------------------
$color__primary: #c6362d;
$color__light: #ffffff;
$color__dark: #222220;
$color__grey: #b0b0b0;
$color__grey_alt: #636362;

$color__purple: #54287f;
$color__yellow: #dc9124;
$color__green: #5ea895;
$color__blue: #5899d4;


$font__base: 'Interstate';
$font__title: 'Interstate Condensed';




// --------- BREAKPOINTS ---------
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;

@mixin responsive($breakpoint) {
  @if $breakpoint == xs {
    @media (max-width: $breakpoint-xs - 1) { @content; }
  }
  @else if $breakpoint == sm {
    @media (max-width: $breakpoint-sm - 1) { @content; }
  }
  @else if $breakpoint == md {
    @media (max-width: $breakpoint-md - 1) { @content; }
  }
  @else if $breakpoint == lg {
    @media (max-width: $breakpoint-lg - 1) { @content; }
  }
}