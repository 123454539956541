@import 'base';

.capeb-sommaire {
  
}


.ra-resume-block {
  background: $color__primary;
  color: $color__light;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  text-align: center;
  margin: 1rem 0px;
  
  &__row {
    margin-bottom: 0.5rem;
    
    &:last-child {
      margin-bottom: 0px;
    }
  }
  
  &__title {
    font-weight: normal;
    font-size: 1rem;
    margin: 0px 0px 0.25rem;
    line-height: 1.2;
  }
  
  &__value {
    font-size: 1.75rem;
    line-height: 1;
    font-weight: bold;
    font-family: $font__title;
  }
  
  @include responsive(lg) {
    padding: 0.5rem;
    &__row {
      margin-bottom: 0.25rem;
    }
    &__title {
      font-size: 1rem;
    }
    
    &__value {
      font-size: 1.5rem;
    }
  }
}




.capeb-tableau-synthetique {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  
  &__table {
    width: 100%;
    
    td, th {
      border-collapse: collapse;
      padding: 0.375rem 0.25rem;
      border: 1px solid $color__light;
    }
  }
  
  &__head {
    th {
      padding: 0.5rem 0.25rem;
      text-align: center;
      &:not(:first-child) {
        color: $color__light;
        background: $color__dark;
        border: 1px solid $color__light;
      }
    }
  }
  
  &__category {
    td {
      padding: 0.5rem 0.25rem;
      background: $color__primary;
      color: $color__light;
      font-weight: bold;
      border-color: $color__dark;
      border-top: 3px solid $color__dark;
    }
  }
  
  &__subcategory {
    td {
      padding: 0.25rem;
      background: lighten($color__primary, 20%);
      color: $color__light;
      border-color: $color__dark;
    }
  }
  
  &__row {
    td {
      border-color: $color__dark;
    }
  }
  
  &__label {
    width: 50%;
    min-width: 5rem;
  }
  &__value {
    width: 10%;
    text-align: center;
    min-width: 3rem;
  }
}


.capeb-sommaire {
  
  
  @media (min-width: 576px) {
    &__rubriques {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      gap: 0px;
    }
    &__rubrique--1 {
      grid-row: span 2 / span 2;
    }
  }
}


.capeb-analyse-departementale {
  &__title {
    background: $color__primary;
    color: $color__light;
    padding: 0.5rem 0.75rem;
    margin: 1.5rem 0rem 0.75rem;
    font-size: 1.5rem;
  }
  
  &__subtitle {
    display: inline-block;
    background: $color__dark;
    color: $color__light;
    padding: 0.325rem 0.375rem;
    font-size: 1.25rem;
    margin-top: 1.5rem;
  }
  
  &__table {
    td, th {
      border: 1px solid $color__dark;
      border-collapse: collapse;
      padding: 0.25rem 0.5rem;
    }
    thead {
      th {
        font-size: 0.875rem;
        text-align: center;
      }
    }
  }
  &__label {
    width: 55%;
  }
  &__value {
    width: 15%;
    
    tbody & {
      text-align: right;
    }
  }
}


.ra-map {
  svg {
    width: 100%;
  }
  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
  &__map {
    flex: 0 0 70%;
  }
  &__legend {
    flex: 0 0 30%;
    padding: 1rem 0px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
  }
  
  @include responsive(xs) {
    &__wrapper {
      display: block;
    }
    &__map {
      flex: 0 0 100%;
    }
    &__legend {
      flex: 0 0 100%;
    }
  }
}